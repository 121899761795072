import React from 'react'
import { NavLink } from 'react-router-dom'

const DashboardHeader = () => {
  return (
    <NavLink className="nav" to='/'>
      <img
        src="images/Genie-Logo.png"
        alt="Genie Sign"
        className='SidebarLogo' />
    </NavLink>
  )
}

export default DashboardHeader;