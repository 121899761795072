import React from 'react';
//import { NavLink } from 'react-router-dom';
import "./App.css";

const NavBar = () => {
    return (
        <>
        <nav className = "main-nav">
        {/*Logo Part*/}
    <div className = "logo">
        <a className="nav" href = "https://geniesign.io/">
            <img 
                src="images/Genie-Logo.png"
                alt="Genie Sign" 
                className = 'Logo-main' />
            </a>
    </div>
    
        {/* Main Menu*/}

        {/* <div className = "menu-link">
            <ul>
                <li><NavLink className="link" to = '/login'> Login </NavLink></li>
            </ul>   
        </div> */}
        </nav>   
        </>
    );
};

export default NavBar;