import React from 'react';

function Footer() {

    return(

        <div className="Footer-Div">
			<div className="centeritems">
				<p className="Footer-Bar">
				© GenieSign 2023. All rights reserved. 
					<a className="Footer-Links" href="https://geniesign.io/terms/">
						Term of Use |
					</a>
					<a className="Footer-Links" href="https://geniesign.io/privacy-policy/">
						Privacy Policy |
					</a>
					<a className="Footer-Links" href="https://geniesign.io/faq/">
						FAQs 
					</a>
				</p>
			</div>
				
				<div className="rightitems">
				<p className="ferelease">
						{process.env.REACT_APP_DATE}.{process.env.REACT_APP_RELEASE_NUMBER} {
							process.env.REACT_APP_URL === "https://d.app.geniesign.io" ? "D" :
							process.env.REACT_APP_URL === "https://t.app.geniesign.io" ? "T" :
							""
						}
					</p>
						
				</div>
			</div>
        
    );
}

export default Footer; 