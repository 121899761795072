import {React, useState, useEffect} from 'react';
import { NavLink, useSearchParams } from 'react-router-dom'
import "./App.css";
import Divider from '@mui/material/Divider';
import DashboardHeader from './DashboardHeader';
import URL from './config.js'


const Sidebar = (props) => {
    return (
        <div className='dashboard-left'>
            <DashboardHeader />
            <div className='main-bar'>
                <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25 }} />
                <div className="sidebarmenulink">
                    <ul>
                        <NavLink href='/dashboard' className="link">
                            <li><img src="images/Dashboard-Grey.png" style={{ width: 15 }} /><span>Dashboard</span></li>
                        </NavLink>

                        <a href={'https://billing.geniesign.io/p/login/test_5kA2bVbwzdg333q7ss?prefilled_email='+props.stripe_email} className="link">
                            <li><img src="images/Payment-Grey.png" style={{ width: 15 }} /><span>Billing</span></li>
                        </a>
                     </ul>
                  </div>
           </div>
          </div>
    );
};

export default Sidebar;