import { React, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridRowsProp } from '@mui/x-data-grid'
import LogoutIcon from '@mui/icons-material/Logout';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink } from 'react-router-dom';
import { red } from '@mui/material/colors';
import URL from './config.js'

const DashboardMain = () => {
    let DashboardEmail = null;

    const [AdminName, SetAdminName] = useState();
    const [PendingSignatures, SetPendingSignatures] = useState();
    const [TotalSignatures, SetTotalSignatures] = useState();
    const [TotalUsers, SetTotalUsers] = useState();
    const [FirstUnpaidSignatureDate, SetFirstUnpaidSignatureDate] = useState();
    const [SignaturesGeneratedYesterday, SetSignaturesGeneratedYesterday] = useState();
    const [NewUsersTodayCount, SetNewUsersTodayCount] = useState();
    const [Open, SetOpen] = useState(false);
    const [isProfileMenuOpen, SetisProfileMenuOpen] = useState(false);
    const [AllSignaturesTableData, SetAllSignaturesTableData] = useState([]);
    const [emailadd, setemailadd] = useSearchParams();

    const SignatureTableColumns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'FullName', headerName: 'Full Name', width: 200 },
        { field: 'PositionTitle', headerName: 'Position Title', width: 200 },
        { field: 'Email', headerName: 'Email', width: 200 },
        { field: 'CreatedOn', headerName: 'Created On', width: 120 },
        { field: 'Extension', headerName: 'Extension', width: 130 },
        { field: 'LineNumber', headerName: 'Line Number', width: 200 },
        { field: 'MobileNumber', headerName: 'Mobile Number', width: 200 },
        {
            field: 'IsSignatureGenerated', headerName: 'Is Signature Generated', width: 200,
            renderCell: (params) => {
                if (params.value === "0") {
                    params.value = <CloseIcon sx={{ color: red[500] }} />
                }
                else {
                    params.value = <DoneIcon color="success" />
                }
                return params.value;
            }
        },
    ]

    const decrypt_email = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                const response = await URL.post('/validateemaildecryptcode',
                    {
                        u_ValidateEmailEncryptKeyIV: emailadd.get('e'),
                        u_ValidateEmailEncryptKeyContent: emailadd.get('c')
                    });
                DashboardEmail = response.data
                resolve(DashboardEmail)
            }, [])
        }, 2000)
    };

    const NavItem = (props) => {
        return (
            <li className="profile-submenu-item">
                <NavLink to={props.to} className="profile-submenu-icon-button" onClick={
                    (event) => {
                        event.stopPropagation();
                        localStorage.clear("token");

                    }}>
                    <div className="icon-button">{props.icon}</div>{props.title}
                </NavLink>
            </li>
        );
    };
    const profile_menu = (event) => {
        return (
            <ul className="profile-submenu">
                <NavItem icon={<LogoutIcon />} title="Logout" to="/login" />
            </ul>
        );
    };
    const toggle_profile_menu = (event) => {
        event.stopPropagation();

        if (isProfileMenuOpen)
            SetisProfileMenuOpen(false);
        else
            SetisProfileMenuOpen(true);
    };
    let AdminEmail;
    useEffect(async () => {
        if (emailadd.get('e') !== null && emailadd.get('c') !== null) {
            AdminEmail = await decrypt_email();
            const response = await URL.post('/fetchallsignatures',
                {
                    u_AdminEmail: AdminEmail,
                })
            if (response) {
                SetOpen(true);
                SetPendingSignatures(response.data.PendingSignatures);
                SetTotalSignatures(response.data.SignTotal);
                SetAdminName(response.data.AdminName);
                SetAllSignaturesTableData(response.data.AllSignatures);
                SetTotalUsers(response.data.TotalUsers);
                SetFirstUnpaidSignatureDate(response.data.FirstUnpaidSignatureDate);
                SetSignaturesGeneratedYesterday(response.data.SignaturesGeneratedYesterday);
                SetNewUsersTodayCount(response.data.NewUsersTodayCount);
            }
        }
    }, []);

    return (
        <div className="dashboard-main">
            <Backdrop
                sx={
                    {
                        color: '#fff',
                        backdropFilter: 'blur(7px)',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }
                }
                open={!Open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="mini-profile">
                <Typography mt={2} mb={1} variant="h5" component="h1" >
                    {AdminName}
                </Typography>
                <img
                    src="images/profile-avatar.png"
                    className="profile-avatar"
                    onClick={toggle_profile_menu}
                />
                {isProfileMenuOpen ? profile_menu() : ''}
            </div>
            <div className="Right-Main-Items">
                <Card className="quickinfobox">
                    <img src="images/Pending-Invoice-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Pending Signatures
                        </Typography>

                        <Typography className="boxcount">
                            {PendingSignatures}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            Pending Signatures Since {FirstUnpaidSignatureDate}
                        </Typography>
                    </CardContent>
                </Card>
                <Card className="quickinfobox">
                    <img src="images/Stationery-Pen-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Total Signatures
                        </Typography>

                        <Typography className="boxcount">
                            {TotalSignatures}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            {SignaturesGeneratedYesterday} Signatures Generated Today
                        </Typography>
                    </CardContent>
                </Card>

                <Card className="quickinfobox">
                    <img src="images/User-Group-512.png" className="quickinfoimg" />
                    <CardContent>
                        <Typography className="boxheading" >
                            Total Users
                        </Typography>

                        <Typography className="boxcount">
                            {TotalUsers}
                        </Typography>

                        <Divider variant="middle" style={{ backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(65, 64, 66), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop: '10px' }} />

                        <Typography className="boxstatus">
                            {NewUsersTodayCount} New users Today
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <Box mt={3} sx={{ width: '100%' }}>
                <Typography mb={1} variant="h6" component="p" >
                    All Signatures
                </Typography>
                <DataGrid
                    className='pd-sign-tbl'
                    rows={AllSignaturesTableData}
                    columns={SignatureTableColumns}
                    autoHeight={true}
                    checkboxSelection={true}
                    rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                />
            </Box>
        </div>
    );
};
export default DashboardMain;