import { Link, useNavigate } from 'react-router-dom';
import "./App.css";
import NavBar from './NavBar';

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from "@mui/material/TextField";
import React, { useState, useEffect} from 'react';
import URL from './config.js';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/material'
import Footer from './Footer.js'

function Login() {
    const [open, setOpen] = React.useState(false)
    const [v_Email, set_v_Email] = React.useState('');
    const [EmailValid, setEmailValid] = useState(null);
    const [v_Password, set_v_Password] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [PassValid, setPassValid] = useState(null);
    const [PassMessage, setPassMessage] = useState('')
    const [usernotfound, setusernotfound] = useState(false)

    const handleConfirmationClose = () => {
		setOpen(false);
	};


    useEffect(() => {
            document.title = "Sign in - GenieSign - Email Signature Generator";
            localStorage.clear("token");
        }, []);

    const navigate = useNavigate();

     
    {/*-------- Password Save to DB ------------*/ }

    const CredentialMatch = async () => {
        setLoading(true);
        const result = await URL.post('/cm',
            {
                u_CredentialEmail: v_Email,
                u_CredentialPassword: v_Password,
            });

            if(result.data.Message === "Matched")
            {
                let encryptPassEmailIV = result.data.PassEmail.iv
                let encryptPassEmailContent = result.data.PassEmail.content
                localStorage.setItem('token', result.data.Token)
                const output = await URL.post('/LoginPremiumCheck',
                {
                    u_PAEmail: v_Email
                });
                let PAResponse = output.data.PAmessage
                setLoading(false)
                navigate(`/Dashboard?e=${encryptPassEmailIV}&c=${encryptPassEmailContent}&p=${PAResponse}`)
                
            }
            else if(result.data.Message === "User not found"){
                setLoading(false)
                setusernotfound(true);
            }
            else
            {
                setLoading(false)
                setOpen(true);
                setusernotfound(false);
            }
    }

    {/*-------- Password Save to DB ------------*/ }

    {/*-------- Email Validation Start------------*/ }

    

    const EmailChange = async(e) => {
        const { value } = e.target;
        set_v_Email(value);

    const validateEmail = (e) => {
        const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g);
        return regEx.test(e);
    };

        const EmailValidation = validateEmail(e.target.value);
        setEmailValid(EmailValidation);
        
}
    {/*-------- Email Validation End------------*/ }

    {/*-------- Password Validation Start------------*/ }

    
    const PassChange = (e) => {
        const { value } = e.target;
        set_v_Password(value);
    };

    const validatePass = (e) => {
        const regEx = new RegExp(/^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/);
        return regEx.test(e);
    };

    const PassHightlight = (e) => {

        const PassValidation = validatePass(e.target.value);
        setPassValid(PassValidation);
        if (PassValidation) {
            setPassMessage("Valid");
        }
        else {
            setPassMessage("In Valid")
        }
        

    }
console.log(v_Email)
    {/*-------- Re - Password Validation End------------*/ }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Container>
            <AppBar position="absolute">
                <Toolbar variant="dense" style={{ backgroundColor: '#434040', justifyContent: 'flex-end', padding:'0px 50px 0px 50px'  }} >
                    <a color="inherit" href="mailto:support@geniesign.io" component={Link} class='ToolbarBtnEmail'>
						<img src="images/Email.png" className='ToolbarImg' />
						support@geniesign.io
					</a>
                </Toolbar>
            </AppBar>

            {/*Header Code Reference Navbar.JS */}
            <NavBar />
            {/*Header Code Reference Navbar.JS */}

            <div className="SP-Form-Main">
                <Typography className="SP-Container-Main">
                    Login
                </Typography>
                <Typography className="SP-Container-Text">
                    Enter Your credentials to access the Dashboard
                </Typography>
            </div>
            <div className="SP-Center-Main" autoFocus>
                <Paper className="SP-Center-TextField" elevation={0} style={{ height: 50, width: 200, marginTop: 0 }}  ></Paper>
                <TextField
                    variant="outlined"
                    size='small'
                    className={
                        v_Email === '' && 'valid' ||  
                        EmailValid === false && 'invalid'
                        }
                    FormHelperTextProps={{style: {color: 'red', height: '40px', marginLeft: '0px'}}}
                    InputProps={{
                        style: {
                            height: '40px',
                            width: '300px',
                            fontSize: '12pt'
                        }
                    }}
                    InputLabelProps={{
                       shrink:true, 
                        style: {
                            fontSize: '11pt',
                            verticalAlign: 'center'
                        }
                    }}

                    
                    type="text"
                    label={"Email"}
                    value={v_Email}
                    onChange={EmailChange}
                    helperText={
                        v_Email === ''? null
                        :EmailValid === true?
                        ""
                        : "Enter a valid email"
                    }
                >

                </TextField>

                <Paper className="SP-Center-TextField" elevation={0} style={{ height: 50, width: 200, marginTop: 0 }}></Paper>
                <TextField
                    variant="outlined"
                    size='small'
                    FormHelperTextProps={{style: {color: 'red', height: 'auto', marginLeft: '0px'}}}
                    InputProps={{
                        style: {
                            height: '40px',
                            width: '300px',
                            fontSize: '12pt',
                        }
                    }}
                    InputLabelProps={{
                        shrink:true,
                        style: {
                            fontSize: '11pt',
                            verticalAlign: 'center'
                        }
                    }}
                    

                    type="password"
                    label={"Password"}
                    value={v_Password}
                    onChange={PassChange}
                    onInput={PassHightlight}
                    >

                </TextField>
                <Paper className="SP-Center-TextField" elevation={0} style={{ height: 30, width: 200, marginTop: 0 }}></Paper>
                {usernotfound && <p className="user-error">The email does not exist</p>}
                <Button className='SP-Btn'
                    // sx={{
                    //     backgroundColor:'#ED1C24'                        
                    // }}
                    disabled={
                        v_Email === '' || v_Password === '' || EmailValid === false || loading
                    } onClick={CredentialMatch}>
                    Login
                    {loading && (
					              <CircularProgress
						            size={25}
						            sx={{
							          color: '#414042',
							          position: 'absolute',
							          top: '20%',
							          right: '45%',
							          zIndex: 1,
						        }}
					       />
				        )}
                </Button>                

                 <Snackbar open={open} autoHideDuration={5000} onClose={handleConfirmationClose}>
        					<Alert onClose={handleConfirmationClose} severity="error" sx={{ width: '100%' }}>
          						Invalid Email or Password!
							    </Alert>
      					</Snackbar>
                
                
            </div>
            </Container>
            {/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}
        </div>
    );
}
export default Login;