import React, { useEffect } from 'react';
import URL from './config.js';
import { useNavigate, useSearchParams } from 'react-router-dom';


function Loading(){

    const [sessionid, set_SessionID] = useSearchParams();

    let paymentStatus = null;
    let loadingEmail = null;
    let SubscriptionID = null

    const getItems = () => {
        return new Promise((resolve, reject) => {
            setTimeout(async() => {
                 const response = await URL.post('/fetchSessionInfo',
                 {
                    u_SessionID: sessionid.get('session_id'),
                 })
                    paymentStatus = response.data.Payment_Status;
                    loadingEmail = response.data.Email
                    SubscriptionID = response.data.SubID
                    resolve(paymentStatus)
                    resolve(loadingEmail)
                    resolve(SubscriptionID)  
            },[])
            }, 2000)
        }
    
    const navigate = useNavigate();
        
    useEffect(async () => {
        document.title = "Moving to Dashboard of GenieSign - Signature Generator";
        const PaymentStatusValue = await getItems();
        const response = await URL.post('/UpdateCompany',
            {
                u_CusPaymentStatus: PaymentStatusValue,
                u_CusEmail: loadingEmail,
                u_SubID: SubscriptionID
            });
            let encryptEmailIV = response.data.LEmail.iv;
                let encryptEmailContent = response.data.LEmail.content;
            if(response.data.Message === "Updated")
            {
                navigate(`/Dashboard?e=${encryptEmailIV}&c=${encryptEmailContent}&p=${response.data.pstatus}`);
            }
    }, [])

    return (

        <p>Processing......</p>

    );
}
export default Loading;