//import React from 'react';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import URL from './config.js'
import { useCookies } from 'react-cookie';

//import "fontsource-roboto";
import "./App.css";
import NavBarHome from './NavBarHome';
import Footer from './Footer'

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import TextField from "@mui/material/TextField";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Container } from '@mui/material';
import axios from 'axios'


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Home() {

	const [open, setOpen] = React.useState(false);
	const [openConfirmation, setOpenConfirmation] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [v_FullName, set_v_FullName] = React.useState('');
	const [v_PositionTitle, set_v_PositionTitle] = React.useState('');
	const [v_LineNumber, set_v_LineNumber] = React.useState('');
	const [v_Extension, set_v_Extension] = React.useState('');
	const [v_MobileNumber, set_v_MobileNumber] = React.useState('');
	const [v_CompanyName, set_v_CompanyName] = React.useState('');
	const [v_CompanyUrl, set_v_CompanyUrl] = React.useState('');
	const [v_CompanyLogoUrl, set_v_CompanyLogoUrl] = React.useState('');
	const [v_Email, set_v_Email] = React.useState();
	const [v_ITManagerEmail, set_v_ITManagerEmail] = React.useState();
	const [selectedImage, setSelectedImage] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [cookies, setCookie] = useCookies(['user']);
	const [ip, setIP] = React.useState('');
	const [iplocation, setIPLocation] = React.useState('');
	const [URLValid, setURLValid] = useState(null);
	const [NameValid, setNameValid] = useState(null);
	const [PositionTitleValid, setPositionTitleValid] = useState(null);
	const [UserEmailValid, setUserEmailValid] = useState('');
	const [FetchingURLValid, setFetchingURLValid] = useState('');
	const [CompanyNameValid, setCompanyNameValid] = useState(null);
	const [ITEmailValid, setITEmailValid] = useState(null);
	const timer = React.useRef();

	React.useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const handleClickOpen = () => {

	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirmationClose = () => {
		setOpenConfirmation(false);
	};

	useEffect(() => { document.title = "GenieSign - Email Signature Generator"; }, []);

	{/*Client IP and Other Details Fetch*/ }

	const getData = async () => {
		const res = await axios.get('https://geolocation-db.com/json/')
		setIP(res.data.IPv4)
		setIPLocation(res.data.country_name)
	}

	useEffect(() => {
		//passing getData method to the lifecycle method
		getData()

	}, [])

	{/*Client IP and Other Details Fetch*/ }


	{/* Copy Signature and Limit Exceed PopUp */ }
	let generate = null;
	let type = '';

	const Copy = async () => {

		if (!loading) {
			setLoading(true);
			setSuccess(false);
			const response = await URL.post('/limitresponse',
				{
					u_resEmail: v_Email,
					u_resCompanyURL: v_CompanyUrl

				});
			generate = response.data.Generate
			type = response.data.Type
			if (response.data.Message === "Allow") {

				saveSignatureToDB();

				//---Copy Signature---//
				var doc = document,
					text = doc.getElementById("theSign"),
					range,
					selection;

				if (doc.body.createTextRange) {
					range = doc.body.createTextRange();
					range.moveToElementText(text);
					range.select();
				}
				else if (window.getSelection) {
					selection = window.getSelection();
					range = doc.createRange();
					range.selectNodeContents(text);
					selection.removeAllRanges();
					selection.addRange(range);
				}
				document.execCommand("copy");
				window.getSelection().removeAllRanges();
				//---Copy Signature---//

				timer.current = window.setTimeout(() => {
					setSuccess(true);
					setLoading(false);
				}, 2000);

				timer.current = window.setTimeout(() => {
					setSuccess(false);
					setLoading(false);
				}, 4000);
			}
			else if (response.data.Message === "Not Allow") {
				saveSignatureToDB();
				const result = await URL.post('/requestedemail', {
					u_UserCompanyURL: v_CompanyUrl,
				})
				set_v_ITManagerEmail(result.data)
				setOpen(true);
				setLoading(false);
			}
		}
	}

	{/* Copy Signature and Limit Exceed PopUp */ }


	{/* --------------------------------------------------------------------------------*/ }

	useEffect(() => {
		if (selectedImage) {
			setImageUrl(URL.createObjectURL(selectedImage));
		}
	}, [selectedImage]);

	const saveSignatureToDB = async () => {
		const result = await URL.post('/signaturecreate',
			{
				u_FullName: v_FullName,
				u_PositionTitle: v_PositionTitle,
				u_LineNumber: v_LineNumber,
				u_Extension: v_Extension,
				u_MobileNumber: v_MobileNumber,
				u_Email: v_Email,
				u_CompanyName: v_CompanyName,
				u_CompanyUrl: v_CompanyUrl,
				u_CompanyLogoUrl: v_CompanyLogoUrl,
				u_SignatureGenerated: generate,
				u_signIP: ip,
				u_signIPLocation: iplocation,
				u_subtype: type,
			})
		if (result.data === "Data Saved" && generate === true && type === "Paid") {
			subscriptionUpdate();
		}
		else {

		}

	}

	const subscriptionUpdate = () => {
		URL.post('/updatesubs',
			{
				u_subCompanyURL: v_CompanyUrl
			})
	}



	const EmailSent = () => {
		URL.post('/ITEmail',
			{
				u_ITManagerEmail: v_ITManagerEmail,
				u_SignFullName: v_FullName,
				u_SignCompanyName: v_CompanyName,
				u_SignCompanyURL: v_CompanyUrl,
				u_SignUserEmail: v_Email,
				u_RequestIP: ip,
				u_RequestIPLocation: iplocation
			}).catch((error) => {
				console.log(error);
			});
		handleClose();
		set_v_ITManagerEmail('')
		setOpenConfirmation(true);
	}


	const FetchCNCLURL = async (AURL) => {
		const response = await URL.post('/cdcl',
			{
				u_CompanyChange: AURL
			}
		)
		if (response.data.Message === "Matched") {
			set_v_CompanyName(response.data.DBCN)
			set_v_CompanyLogoUrl(response.data.DBCLURL)
		}
		else {
			set_v_CompanyName('')
			set_v_CompanyLogoUrl('')
		}
	}

	{/* ----------------------------------VALIDATIONS START--------------------------------------------*/ }

	{/*-------- URL Validation Start------------*/ }

	const URLChange = async (e) => {

		console.log("TEST",e.target.value)
		const { value } = e.target;
		set_v_CompanyUrl(value);

		const validateUrl = (e) => {
			const regEx = new RegExp(/^[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,5}$/g);
			return regEx.test(e);
		};

		const URLChecked = validateUrl(e.target.value);
		setURLValid(URLChecked);
	};
	{/*-------- URL Validation Start------------*/ }

	const validateFullName = (e) => {
		const regEx = new RegExp(/^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s\p{P}]*$/);
		return regEx.test(e);
	}
	const validateCompanyName = (e) => {
		const regEx = new RegExp(/^[0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\s\p{P}.&-]*$/);
		return regEx.test(e);
	}

	{/*-------- Full Name Validation Start------------*/ }
	
	const FullNameChange = (e) => {

		const { value } = e.target;
		set_v_FullName(value);

		const result = validateFullName(e.target.value);
		setNameValid(result)
	};

	{/*-------- Full Name Validation End------------*/ }

	{/*-------- Position Title Validation Start------------*/ }
	
		const PositionTitleChange = (e) => {
		set_v_PositionTitle(e.target.value)
		
		const result = validateFullName(e.target.value); //Line 282-289
		setPositionTitleValid(result)
	};

	{/*-------- Position Title Validation End------------*/ }

	{/*-------- Line Number Validation Start------------*/ }

	const LineNumberChange = (e) => {
		const result = e.target.value.replace(/[^0-9-()]/gi, '');
		set_v_LineNumber(result)
	};

	{/*-------- Line Number Validation End------------*/ }

	{/*-------- Extension Validation Start------------*/ }

	const ExtensionChange = (e) => {
		const result = e.target.value.replace(/[^0-9]/gi, '');
		set_v_Extension(result)
	};

	{/*-------- Extension Validation End------------*/ }

	{/*-------- Mobile Number Validation Start------------*/ }

	const MobileNumberChange = (e) => {
		const result = e.target.value.replace(/[^0-9-+ " "]/gi, '');
		set_v_MobileNumber(result)
	};

	{/*-------- Mobile Number Validation End------------*/ }

	{/*-------- Email Validation Start------------*/ }

	const EmailChange = async (e) => {

		let EV = false
		let UV = false

		const { value } = e.target;
		set_v_Email(value);

		///^[^\s@]+@[^\s@]+\.[^\s@]+$/

		const validateEmail	 = (e) => {
			const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g;
			return regEx.test(e);
		}

		const EmailValidation = validateEmail(e.target.value);
		EV = EmailValidation;
		setUserEmailValid(EmailValidation);
		const AURL = e.target.value.substring(
			e.target.value.indexOf("@") + 1,
			e.target.value.lastIndexOf("")
		)

		const FetchingValidateURL = (AURL) => {
			const regEx = /^[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,5}$/g;
			return regEx.test(AURL);
			};
				const URLValidation = FetchingValidateURL(AURL);
				UV = URLValidation;
				setFetchingURLValid(URLValidation);

		if (EV === true && UV === true) {

			set_v_CompanyUrl(AURL)
			await FetchCNCLURL(AURL)
		}
		else {
			set_v_CompanyName('')
			set_v_CompanyLogoUrl('')
			set_v_CompanyUrl('')
		}
		
	}

	{/*-------- Email Validation End------------*/ }


	{/*-------- Company Name Validation Start------------*/ }

	
	const CompanyChange = async (e) => {
		
		set_v_CompanyName(e.target.value);
		const result = validateCompanyName(e.target.value) //Line 282-289
		setCompanyNameValid(result);

	};
	{/*-------- Company Name Validation End------------*/ }

	{/*-------- IT Manager Validation Start------------*/ }

	const ITEmailChange = async (e) => {
		const { value } = e.target;
		set_v_ITManagerEmail(value);

		const validateEmail	 = (e) => {
			const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+\@[a-zA-Z0-9_\-\.]+\.[a-zA-Z]{2,3}$/g);
			return regEx.test(e);
		};

		if(v_ITManagerEmail != null){
			const ITEmailCheck = validateEmail(e.target.value);
			setITEmailValid(ITEmailCheck);
		}
	};

	{/*-------- IT Manager Validation End------------*/ }


	{/* ----------------------------------VALIDATIONS END--------------------------------------------*/ }


	{/* --------------------------------------------------------------------------------*/ }

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Container>
			<AppBar position="absolute">
				<Toolbar variant="dense" style={{ backgroundColor: '#434040', justifyContent: 'flex-end', padding:'0px 50px 0px 50px' }} >

					<Button color="inherit" component={Link} to="/login" className='ToolbarBtnLogin' sx={{textTransform:"capitalize"}}>
						<img src="images/Login.png" className='ToolbarImg' />
						Company Login
					</Button>

					{/* <Button color="inherit" component={Link} to="/signup" class='ToolbarBtnEmail'>
						<img src="images/Email.png" className='ToolbarImg' />
						support@geniesign.io
					</Button> */}

					<a color="inherit" href="mailto:support@geniesign.io" component={Link} class='ToolbarBtnEmail'>
						<img src="images/Email.png" className='ToolbarImg' />
						support@geniesign.io
					</a>
				</Toolbar>
			</AppBar>

			{/*Header Code Reference Navbar.JS */}
			<NavBarHome />
			{/*Header Code Reference Navbar.JS */}

			<div className="Form-Main">
				<TableContainer component={Paper} className="Container-Main">
					<Table size='small' style={{ marginBottom: '10px' }}>
						<TableBody>
							<TableRow>
								<TableCell style={{ width: 400, borderBottom: "none" }}>
									<Paper elevation={0} class="Style-Heading">
										<Typography class="Typo-Heading">
											Personal Details
										</Typography>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 'auto', width: 400 }} >
										<TextField
											variant="outlined"
											size='small'
											className={
												v_FullName === '' && 'valid' ||  
												NameValid === false && 'invalid'
												}
											FormHelperTextProps={{style: {color: 'red', height: '15px', marginLeft: '0px'}}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/FullName.png" />
													</InputAdornment>
												),

												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans',
												}

											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_FullName || "Full Name"}
											value={v_FullName}
											onChange={FullNameChange}
											helperText={
												v_FullName === '' ? "" :
												NameValid === false ? "Full Name cannot contains Special Characters and Numbers" :
												""
											}
										>
										</TextField>

									</Paper>
								</TableCell>


								{/*==========================================================================================================================================================*/}
								</TableRow>

							{/*-------------------------------------------------------------------------------------------------------------*/}
							<TableRow>
								<TableCell style={{ borderBottom: "none", }}>
									<Paper elevation={0} style={{ height: 'auto', width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											className={
												v_PositionTitle === '' && 'valid' ||
												PositionTitleValid === false && 'invalid'
											}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Position.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_PositionTitle || "Position Title"}
											value={v_PositionTitle}
											onChange={PositionTitleChange}
											FormHelperTextProps={{style: {color: 'red', height: '15px', marginLeft: '0px'}}}
											helperText={
												v_PositionTitle === '' ? "" :
												PositionTitleValid === false ? "Position Title cannot contains Special Characters and Numbers" :
												""
											}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 35, width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Telephone.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_LineNumber || "Phone Number"}
											value={v_LineNumber}
											onChange={LineNumberChange}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 35, width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Extension_Grey.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_Extension || "Extension"}
											value={v_Extension}
											onChange={ExtensionChange}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 35, width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Mobile.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}
											type="text"
											label={cookies.c_MobileNumber || "Mobile Number"}
											value={v_MobileNumber}
											onChange={MobileNumberChange}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 'auto', width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											className={
														v_Email === '' && 'valid' ||  
														UserEmailValid === false && 'invalid'
														}
											FormHelperTextProps={{style: {color: 'red', height: '10px', marginLeft: '0px'}}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Mail.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												shrink: true,
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_Email || "Email"}
											value={v_Email}
											onInput={EmailChange}
											helperText={
												v_Email === '' ? "" :
												UserEmailValid === false ? "Invalid Email" :
												""
											}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} class="Style-Heading">
										<Typography class="Typo-Heading">
											Company Details
										</Typography>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 'auto', width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											className={
												v_CompanyName === '' && 'valid' ||  
												CompanyNameValid === false && 'invalid'
												}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Company.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_CompanyName || "Company Name"}
											value={v_CompanyName}
											onChange={CompanyChange}
											FormHelperTextProps={{style: {color: 'red', height: '15px', marginLeft: '0px'}}}
											helperText={
												v_CompanyName === '' ? "" :
												CompanyNameValid === false ? "Company Name cannot contains Special Characters" : ""
											}

										>
										</TextField>

									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 'auto', width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											className={
														FetchingURLValid === true && 'valid' ||
														v_CompanyUrl === '' && 'valid' ||  
														URLValid === false && 'invalid' 

													}
											FormHelperTextProps={{style: {color: 'red', height: '15px', marginLeft: '0px'}}}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Website.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={cookies.c_CompanyUrl || "Company Website"}
											value={v_CompanyUrl}
											onInput={URLChange}
											helperText={
												v_CompanyUrl === '' ? "" :
												FetchingURLValid === true ? "" :
												URLValid === false ? "Invalid URL" :
												
												""
											}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell style={{ borderBottom: "none" }}>
									<Paper elevation={0} style={{ height: 35, width: 400, 'margin-top': 0 }} >
										<TextField
											variant="outlined"
											size='small'
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<img src="images/Link.png" />
													</InputAdornment>
												),
												style: {
													height: '35px',
													width: '400px',
													fontSize: '13px',
													fontFamily: 'Nunito Sans'
												}
											}}
											InputLabelProps={{
												style: {
													fontSize: '12px',
												}
											}}

											type="text"
											label={"Company Logo Url / Link"}
											value={v_CompanyLogoUrl}
											onChange={(event) => {
												set_v_CompanyLogoUrl(event.target.value)
											}}
										>
										</TextField>
									</Paper>
								</TableCell>
							</TableRow>



						</TableBody>
					</Table>
					<Table>
						<TableRow>
						<TableCell rowSpan={7} align='left' style={{ width: 630, height: 200, background: 'white', borderBottom: "none" }} >

									
{
		FetchingURLValid === true && UserEmailValid === true && v_FullName !== '' && v_PositionTitle !== '' && v_MobileNumber !== '' && v_CompanyName !== '' && v_CompanyUrl !== '' && v_CompanyLogoUrl !== '' && NameValid === true &&

	<Box className="Sign-Btn" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
		<Box sx={{ m: 1, position: 'relative'}}>
			<Fab
				aria-label="save"
				sx={{					
					color: '#fff',
					bgcolor: '#414042',
					fontSize: '1rem',
					width:'45px',
					height: '45px',
					'&:hover':{
						color: '#414042',
						bgcolor: '#fff'
					},
					...(success === true && {
						bgcolor: green[500],
							'&:hover': {
								bgcolor: green[700],
							},
						}),
					}}
					onClick={Copy}
				>
					{success ? <CheckIcon /> : <ContentCopyIcon />}
				</Fab>
				{loading && (
					<CircularProgress
						size={56}
						sx={{
							color: '#414042',
							position: 'absolute',
							top: -6,
							left: -6,
							zIndex: 1,
						}}
					/>
				)}
			</Box>
			<Box sx={{ m: 1, position: 'relative' }}>
				<Button
					variant="contained"
					sx={{
						color: '#fff',
						bgcolor: '#414042',
						fontSize: '12px',
						'&:hover': {
							bgcolor: '#fff',
							color: '#414042',
						},						
						...(success === true && {
							bgcolor: green[500],
							'&:hover': {
								bgcolor: green[700],
							},
						}),
					}}
					disabled={loading}
					onClick={Copy}
				>
					Copy to clipboard
				</Button>
				{loading && (
					<CircularProgress
						size={24}
						sx={{
							color: '#414042',
							position: 'absolute',
							top: '50%',
							left: '50%',
							marginTop: '-12px',
							marginLeft: '-12px',
						}}
					/>
				)}
			</Box>
		</Box>
	}

	<table
		id="theSign"
		style={{ border: '1px solid #ED1C24', height: 250 }}
		
	>
		<tr>
			<td style={{ height: '20px' }}>

				<img
					style={{ width: '200px' }}
					id="iSImage"
					src={v_CompanyLogoUrl || cookies.c_CompanyLogoUrl || 'images/Logo-Placeholder.png'}

				/>


			</td>
			<td id="sign-details" style={{ background: 'white' }} >
				<table style={{ borderLeft: '2px solid #414042', width: '250px' }}>
					<tr>
						<td colSpan={2} className="Full-Name">
							{v_FullName || cookies.c_FullName || "Name"}
						</td>
					</tr>
					<tr>
						<td colSpan={2} className="Position">
							{v_PositionTitle || cookies.c_PositionTitle || "Position"}
						</td>
					</tr>
					<tr>
						<td colSpan={2} className="Company">
							{v_CompanyName || cookies.c_CompanyName || "Company"}
							<div className="Signature-Border" >

							</div>
						</td>
					</tr>
					{
						v_LineNumber !== '' &&
						<tr>
							<td className="Icons">
								<img src="images/Sign_Contact.png" style={{ width: 14, height: 14 }} />
							</td>
							<td className="Details">

								{v_LineNumber || cookies.c_LineNumber}
								{v_Extension !== '' && " x " +
									v_Extension
								}


							</td>
						</tr>
					}

					{
						v_MobileNumber !== '' &&
						<tr>
							<td className="Icons">
								<img src="images/Sign_Mobile.png" style={{ width: 14, height: 14 }} />
							</td>
							<td className="Details">
								{v_MobileNumber || cookies.c_MobileNumber || "Mobile Number"}
							</td>
						</tr>
					}


					<tr>
						<td className="Icons" >
							<img src="images/Sign_Mail.png" style={{ width: 14, height: 14 }} />
						</td>
						<td className="Details">
							{v_Email || cookies.c_Email || "Email"}
						</td>
					</tr>

					<tr>
						<td className="Icons">
							<img src="images/Sign_Website.png" style={{ width: 14, height: 14 }} />
						</td>
						<td className="Details">
							<a tabindex="-1" href={"https://"+v_CompanyUrl} target="_blank">{v_CompanyUrl || cookies.c_CompanyUrl || "Website"}</a>
						</td>
					</tr>

				</table>
			</td>
		</tr>

	</table>

</TableCell>
						</TableRow>
					</Table>
				</TableContainer>
			</div>
			</Container>

			{/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}

			{/*---Limit Exceed PopUp---*/}

			<div >
				<Dialog onClick={handleClickOpen}
					PaperProps={{
						style: {
							width: '1500px',
							maxHeight: 500,
							backgroundColor: '#FFF'
						}
					}}
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					//aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogTitle disableTypography class="PopUp-Heading">{"OOPS!"}</DialogTitle>
					<DialogContent style={{ backgroundColor: '#FFF' }}>
						<DialogContentText class="PopUp-Text">
							Your organisation has reached the limit of 5 free email signatures. Let us request IT Manager/CIO
							of {v_CompanyName} to signup and help you create your own email signature.
						</DialogContentText>
					</DialogContent>
					<TableRow style={{ backgroundColor: '#FFF' }}>
						<TableCell style={{ borderBottom: "none" }}>
							<Paper elevation={0} style={{ height: 35, width: 400, 'margin-top': 0, backgroundColor: '#FFF', marginLeft: '80px' }} >
								<TextField
									variant="outlined"
									size='small'
									className={ITEmailValid === false && 'invalid'}
									InputProps={{
										style: {
											height: '35px',
											width: '400px',
											fontSize: '13px',
											fontFamily: 'Nunito Sans'
										}
									}}
									InputLabelProps={{
										shrink: true,
										style: {
											fontSize: '12px',
										}
									}}

									type="text"
									label={"CIO / IT Manager Email"}
									value={v_ITManagerEmail}
									onChange={ITEmailChange}
									helperText={
										ITEmailValid === false ? "Invalid Email" : ""
									}
								>
								</TextField>

							</Paper>
						</TableCell>
					</TableRow>
					<DialogActions style={{ backgroundColor: '#FFF', padding: 20 }}>
						<Button class="PopUp-Btn-Close" onClick={handleClose}>
							Close
						</Button>
						<Button 
						disabled=
						{
							v_ITManagerEmail === '' || ITEmailValid === false
						} 
							onClick={EmailSent} className="PopUp-Btn">
							Send Request
						</Button>


					</DialogActions>
				</Dialog>
				<Snackbar open={openConfirmation} autoHideDuration={5000} onClose={handleConfirmationClose}>
					<Alert onClose={handleConfirmationClose} severity="success" sx={{ width: '100%' }}>
						Email has been Sent.
					</Alert>
				</Snackbar>
			</div>

			{/*---Limit Exceed PopUp---*/}
		</div>


	);

}
export default Home;
