import React from 'react';
import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useState , useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import URL from './config.js'
import Footer from './Footer.js'


const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', backgroundColor: 'Yellow' }}
    >
        •
    </Box>
);



function Dashboard() {

    const [DashboardEmail, setDashboardEmail] = useState('')
    const [emailadd, setemailadd] = useSearchParams();
    let paymentValue;

    
    
    const navigate = useNavigate();
    const localstoragetoken = localStorage.getItem("token");

    
    useEffect(async () => {
        document.title = "Dashboard of GenieSign - Email Signature Generator";
        if(localstoragetoken !== null && emailadd.get('e') !== null && emailadd.get('c') !== null){
            const response = await URL.post('/dashboarddecryptcode',
            {
                u_DashboardEncryptKeyIV: emailadd.get('e'),
                u_DashboardEncryptKeyContent: emailadd.get('c'),

            });
            setDashboardEmail(response.data);
         
        }
        else{
            navigate("/login");
        }
    }, [])

      

    useEffect(async() => {
        paymentValue = emailadd.get('p')
        if(paymentValue === "Paid")
        {
            const output = await URL.post('/signgenemail',
            {
                u_SignGenEmail: DashboardEmail,
                token: localstoragetoken,
            }
            )
        }
    })

    const integration = async () => {
        try {
            const result = await URL.post('/subs',
                {
                    u_SubEmail: DashboardEmail,
                    token: localstoragetoken,
                    u_DashboardEncryptKeyIV: emailadd.get('e'),
                    u_DashboardEncryptKeyContent: emailadd.get('c'),
                    Payment: emailadd.get('p')
                })
            window.location.replace(result.data.url);
        }
        catch (error) {
            navigate("/login");
        }
    }



    return (
        <div>
            {
                !paymentValue ? <Alert className="alert-top" severity="warning" >
                    Your payment has not been set up, please click <Button onClick={integration}>here </Button>to set up payment
                </Alert>
                    : null
            }

            <div className='sidebarMain'>
                <Sidebar stripe_email ={DashboardEmail} />
                <Outlet />
            </div>


            {/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}
        </div>
    );
}

export default Dashboard;
