import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

const Profile = () =>
{
    return(
        <div class="dashboard-main">
            <div className = "Right-Main-Items">
                <Card className="quickinfobox">
                <img src="images/Pending.png" className="quickinfoimg"/>
                    <CardContent>
                        <Typography className="boxheading" >
                            Pending Signatures
                        </Typography>

                        <Typography className = "boxcount">
                            7
                        </Typography>

                        <Divider variant="middle" style={{backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(255, 255, 255), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop:'10px' }} />
                    
                        <Typography className = "boxstatus">
                            34 Pending Signatures Yesterday
                        </Typography>
                    </CardContent>    
                </Card>
                <Card className="quickinfobox">
                <img src="images/Pending.png" className="quickinfoimg"/>
                    <CardContent>
                        <Typography className="boxheading" >
                            Total Signatures
                        </Typography>

                        <Typography className = "boxcount">
                            200
                        </Typography>

                        <Divider variant="middle" style={{backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(255, 255, 255), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop:'10px' }} />
                    
                        <Typography className = "boxstatus">
                            34 Signatures Generated Yesterday
                        </Typography>
                    </CardContent>    
                </Card>
                <Card className="quickinfobox">
                <img src="images/Payment.png" className="quickinfoimg"/>
                    <CardContent>
                        <Typography className="boxheading" >
                            Payment
                        </Typography>

                        <Typography className = "boxcount">
                            $250
                        </Typography>

                        <Divider variant="middle" style={{backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(255, 255, 255), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop:'10px' }} />
                    
                        <Typography className = "boxstatus">
                            Last Payment $500
                        </Typography>
                    </CardContent>    
                </Card>
                <Card className="quickinfobox">
                <img src="images/FreeUsers.png" className="quickinfoimg"/>
                    <CardContent>
                        <Typography className="boxheading" >
                            Free Users
                        </Typography>

                        <Typography className = "boxcount">
                            5
                        </Typography>

                        <Divider variant="middle" style={{backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgb(255, 255, 255), rgba(52, 71, 103, 0))', opacity: 0.25, marginTop:'10px' }} />
                    
                        <Typography className = "boxstatus">
                            Paid Users 35
                        </Typography>
                    </CardContent>    
                </Card>

                </div>
        </div>
        
    );
};
export default Profile;