import Button from '@mui/material/Button'
import React, { Component, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import URL from './config.js'

function Payment() {

    const navigate = useNavigate();

    const Integration = async() => {
        const result = await URL.post('/subs',
        {
            u_SubEmail: "abdulsamipatel@gmail.com",
            u_SubUsers: 50
        })
        console.log(result)

            window.location.replace(result.data.url);
        // if(result.data === "Amount Added")
        // {
        //     navigate('https://buy.stripe.com/test_aEUeWzaLA7eyfUA001');
        // }
        // else{
        //     console.log("Error")
        // }
    }

    return(
        <Button onClick={Integration}>Checkout</Button>
    );
}
export default Payment