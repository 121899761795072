import React from 'react';
import { NavLink } from 'react-router-dom';
import "./App.css";

const NavBar = () => {
    return (
        <>
        <nav className = "main-nav">
        {/*Logo Part*/}
    <div className = "logo">
        <NavLink className="nav" to = '/'>
            <img 
                src="images/Genie-Logo.png"
                alt="Genie Sign" 
                className = 'Logo-main' />
            </NavLink>
    </div>
    
        {/* Main Menu*/}

        {/*<div className = "menu-link">
            <ul>
                <li><NavLink className="link" to = '/cdash'> Dashboard </NavLink></li>
                <li><a className="link" href = "https://geniesign.io/#pricing"> Pricing </a></li>
                <li><a className="link" href = "https://geniesign.io/faq/"> FAQs </a></li>
            </ul>   
        </div>

        <div className = "menu-button">
            <ul className = "signup-desktop">
                <li><NavLink className = "Btn" to = '/signup'> Sign Up </NavLink></li>
            </ul>
    </div>*/}
        </nav>   
        </>
    );
};

export default NavBar;