import { Link, useNavigate } from 'react-router-dom';
import "./App.css";
import NavBar from './NavBar';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import PinInput from 'react-pin-input';
import { useState , useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import URL from './config.js'
import Footer from './Footer.js'

const ValidateEmailAddress = () =>{

    useEffect(async() => {
        document.title = "Validate your email - GenieSign - Email Signature Generator";
        const response = await URL.post('/validateemaildecryptcode',
        {
            u_ValidateEmailEncryptKeyIV: emailadd.get('e'),
            u_ValidateEmailEncryptKeyContent: emailadd.get('c')
        });
        setUserEmail(response.data);
        }, [])

    const [v_code, set_v_code]= useState("");
    const [v_error, set_v_error]= useState("");

    const handleChange = (value, index) => {
        set_v_code(value)
        
    }

    console.log(111111111111, v_code)

    
    
    const navigate = useNavigate();

    const [emailadd, setemailadd] = useSearchParams();
    const [UserEmail, setUserEmail] = useState('');

    console.log(UserEmail)

    const CodeVerified = async() => {
        const result = await URL.post('/codeverification',
        {
            u_VerifyEmail: UserEmail,
            u_UserCode: v_code
        });
        let codeencryptEmailIV = result.data.codeverifiedemail.iv
        let codeencryptEmailContent = result.data.codeverifiedemail.content
        if(result.data.message === 'Verified')
        {
            navigate(`/setpassword?e=${codeencryptEmailIV}&c=${codeencryptEmailContent}`)
        } 
        else
        {
            set_v_error("Code not match please enter validate code or open the link from Email!")
        }
    }
    
    
    return(
        <div style={{width:'100%', height:'100%'}}>
			
			<AppBar position="absolute">
		<Toolbar variant="dense" style={{backgroundColor: '#434040'}} >
			
					<a style={{marginLeft: '1300px'}} href="mailto:support@geniesign.io" color="inherit" component={Link} to="/signup" class = 'ToolbarBtn'>
						<img src ="images/Email.png" className='ToolbarImg'/>
							support@geniesign.io
					</a>
			</Toolbar>
	</AppBar>  

	{/*Header Code Reference Navbar.JS */}		
			<NavBar />
	{/*Header Code Reference Navbar.JS */}

    <div className="Validate-Form-Main">
        <Typography class="Validate-Container-Main">
            Validate your Email Address
        </Typography>
        <Typography class="Validate-Container-Text">
        We have sent code to your email address:<br/>
        
        {emailadd.get('email')}
        </Typography>
    </div>
    <div className="Validate-Center-Main">
                                    <Paper className="CYM-Center-CodeInput" elevation={0} ></Paper>
                                    <PinInput
                                        length={6} 
                                        initialValue="" 
                                        onComplete={handleChange}
                                        onChange={handleChange} 
                                        type="numeric" 
                                        inputMode="number" 
                                        inputStyle={{borderColor: '#e8e8e8'}}
                                        inputFocusStyle={{borderColor: 'blue'}}
                                    />
                                    <Button class='Validate-Btn' onClick={CodeVerified}>
                                    Validate
                                </Button>

                                {v_error && <Typography class="Validate-Container-Text">
                                        {v_error}
                                    </Typography>}

    </div>

            {/*Footer Code Reference Footer.js */}
			<Footer />
			{/*Footer Code Reference Footer.js */}

        

    </div>
        
    );
}


export default ValidateEmailAddress;